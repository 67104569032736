export function buscarExtensao(propiedadeNome: string, obj: any) {
  const extensao = obj[propiedadeNome] ? obj[propiedadeNome].split('.').pop().toLocaleLowerCase() : 'png';
  let formato = '';
  switch (extensao) {
    case 'jpeg':
    case 'jpg':
    case 'gif':
    case 'png':
    case 'apng':
    case 'svg':
    case 'webp':
      formato = 'img';
      break;
    case 'mp4':
    case 'webm':
    case 'ogg':
      formato = 'video';
      break;
    case 'plain':
    case 'pdf':
      formato = 'open';
      break;
  }

  return formato;
}
